// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-fotoalbum-js": () => import("/opt/build/repo/src/templates/fotoalbum.js" /* webpackChunkName: "component---src-templates-fotoalbum-js" */),
  "component---src-templates-standart-page-js": () => import("/opt/build/repo/src/templates/standart-page.js" /* webpackChunkName: "component---src-templates-standart-page-js" */),
  "component---src-templates-index-js": () => import("/opt/build/repo/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-leistungen-und-preise-js": () => import("/opt/build/repo/src/pages/leistungen-und-preise.js" /* webpackChunkName: "component---src-pages-leistungen-und-preise-js" */),
  "component---src-templates-news-post-js": () => import("/opt/build/repo/src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anfahrt-js": () => import("/opt/build/repo/src/pages/anfahrt.js" /* webpackChunkName: "component---src-pages-anfahrt-js" */),
  "component---src-pages-kundenerfahrungen-js": () => import("/opt/build/repo/src/pages/kundenerfahrungen.js" /* webpackChunkName: "component---src-pages-kundenerfahrungen-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-submit-form-js": () => import("/opt/build/repo/src/pages/submit-form.js" /* webpackChunkName: "component---src-pages-submit-form-js" */),
  "component---src-pages-termine-js": () => import("/opt/build/repo/src/pages/termine.js" /* webpackChunkName: "component---src-pages-termine-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

